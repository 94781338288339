import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { EntryProps, PaginationOptions, Section as ISection } from '@/types/site.interface'
import Grid from '@ignition/library/components/atoms/grid'
import { GridColumns } from '@ignition/library/components/atoms/grid/interface'
import Pagination from '../molecules/pagination'
import Section from '../molecules/section'

const Teaser = dynamic(() => import('@/components/molecules/teaser'))
const TeaserPortrait = dynamic(() => import('@/components/molecules/teaser-portrait'))

export interface EntryGridProps {
  mainSlug: string
  currentPage: number
  section: ISection
  entries: EntryProps[]
  moreButton?: boolean
  teaserStyle?: string
  pagination: PaginationOptions
  totalPages: number
  columns?: GridColumns
}

const styleGrid = {
  portrait: 'mx-auto max-w-md py-4 px-4 sm:px-16 lg:px-8',
  default: '',
}

const styleParentDiv = {
  portrait: '',
  default: 'flex',
}

const teaserComponent = (style: string) => {
  switch (style) {
    case 'portrait':
      return TeaserPortrait
    default:
      return Teaser
  }
}

const EntryGrid: FC<EntryGridProps> = ({
  section,
  totalPages,
  currentPage,
  entries = [],
  pagination,
  mainSlug,
  teaserStyle = 'default',
  columns = 3,
}) => {
  const teaserGridStyle = teaserStyle ? teaserStyle : 'default'
  const TeaserComponent = teaserComponent(teaserGridStyle)
  return (
    <Section section={section} className="relative">
      <Grid columns={{ base: 1, md: columns }} gap={{ base: 8 }} className={styleGrid[teaserGridStyle]}>
        {entries?.map((item) => {
          return (
            <div key={item._id} className={styleParentDiv[teaserGridStyle]}>
              <TeaserComponent
                {...item}
                id={item._id}
                caption={item?.image?.caption}
                summary={item?.summary?.substring(0, 200)}
              />
            </div>
          )
        })}
      </Grid>
      <Pagination
        className="pt-12"
        baseHref={mainSlug}
        currentPage={currentPage}
        totalPages={totalPages}
        pagination={pagination}
      />
    </Section>
  )
}
export default EntryGrid
